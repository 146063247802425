import Vue from 'vue'
import App from './App.vue'

import MainFunc from './MainFunc.vue'
Vue.mixin(MainFunc);

import VueRouter from 'vue-router'
import router from './router'
Vue.use(VueRouter)

import Meta from 'vue-meta'
Vue.use(Meta)

import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";
Vue.component("v-select", vSelect);

import VueToast from 'vue-toast-notification';
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast);

// import 'bootstrap'
// import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload, {
  preLoad: 1.3,
  // loading: '/assets/lazy.png',
  loading: '/assets/user.jpg',
  error: '/assets/user.jpg',
  attempt: 1
})

import VueSwal from 'vue-swal'
Vue.use(VueSwal)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
