import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

function lazyLoad(view) {
  return () => import(`@/views/${view}.vue`)
}

const routes = [
  {
    path: '/',
    name: 'Login',
    component: lazyLoad("LoginPage")
  },
  {
    path: '/set_password',
    name: 'SetPassword',
    component: lazyLoad("SetPassword")
  },
  {
    path: '/admin',
    component: lazyLoad("Admin/Base"),
    children: [
      // {
      //   path: '',
      //   redirect: 'adminer'
      // },
      {
        path: '',
        name: 'AdminDashboard',
        component: lazyLoad("Admin/Dashboard")
      },
      {
        path: 'adminer',
        name: 'AdminAdminer',
        component: lazyLoad("Admin/Adminer")
      },
      {
        path: 'partner',
        name: 'AdminPartner',
        component: lazyLoad("Admin/Partner")
      },
      {
        path: 'member',
        name: 'AdminMember',
        component: lazyLoad("Admin/Member")
      },
      {
        path: 'station',
        name: 'AdminStation',
        component: lazyLoad("Admin/Station")
      },
      {
        path: 'control',
        name: 'AdminControl',
        component: lazyLoad("Admin/Control")
      },
      {
        path: 'device',
        name: 'AdminDevice',
        component: lazyLoad("Admin/Device")
      },
      {
        path: 'member_log',
        name: 'AdminMemberLog',
        component: lazyLoad("Admin/MemberLog")
      },
      {
        path: 'partner_log',
        name: 'AdminPartnerLog',
        component: lazyLoad("Admin/PartnerLog")
      },
      {
        path: 'admin_log',
        name: 'AdminAdminLog',
        component: lazyLoad("Admin/AdminLog")
      },
      {
        path: 'notification_log',
        name: 'AdminNotificationLog',
        component: lazyLoad("Admin/NotificationLog")
      },
      {
        path: 'information',
        name: 'AdminInformation',
        component: lazyLoad("Admin/Information")
      },
      {
        path: 'password',
        name: 'AdminPassword',
        component: lazyLoad("Admin/Password")
      },
      {
        path: 'mornitor',
        name: 'AdminMornitor',
        component: lazyLoad("Admin/Mornitor")
      },
      // ....................................
      {
        path: 'partner/:id',
        name: 'AdminPartnerID',
        component: lazyLoad("Admin/PartnerID")
      },
      {
        path: 'member/:id',
        name: 'AdminMemberID',
        component: lazyLoad("Admin/MemberID")
      },
      {
        path: 'station/:id',
        name: 'AdminStationID',
        component: lazyLoad("Admin/StationID")
      },
      {
        path: 'control/:id',
        name: 'AdminControlID',
        component: lazyLoad("Admin/ControlID")
      },
      {
        path: 'device/:id',
        name: 'AdminDeviceID',
        component: lazyLoad("Admin/DeviceID")
      },
    ]
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
