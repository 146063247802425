import axios from 'axios';
import md5 from 'md5';

export let mode = "prod"; // "dev" // "prod"

let ROOT = {
    dev: `http://localhost/tnjtek/`,
    prod: `https://api.tnjtek.com/`,
}
let URL = {
    // dev: `http://localhost/new-project/api/`,
    dev: `http://localhost:3000/`,
    prod: `https://api.tnjtek.com/`,
}
let UTILITY = {
    dev: `http://localhost/tnjtek/utility/`,
    prod: `https://utility.tnjtek.com/`,
}

export let rootURL = `${ROOT[mode]}`;
export let baseURL = `${URL[mode]}`;
export let utilityURL = `${UTILITY[mode]}`;

export let secret = md5(`TNJTEK`);

// let HOST = "ws://58.82.142.150:1883"; 
// let HOST = "ws://119.59.99.133:9001";
// let HivMQ = "wss://broker.hivemq.com:8884/mqtt";
let HOST = "wss://tnjtek.com:1884/mqtt";
let USER = "tnjtek";
let PASS = "tnjtek@2023";
let BASE = "TNJTEK";
let TOPIC = [
    `${BASE}/website/notify`,
    `${BASE}/website/update`,
    `${BASE}/website/reload`,
    `${BASE}/login/admin`,
    `${BASE}/payment`,
];

export let MQTT = {
    HOST: HOST,
    USER: USER,
    PASS: PASS,
    TOPIC: TOPIC,
    BASE: BASE,
}

export default axios.create({
    baseURL
});